import React, { useState, useEffect } from "react"
import Layout from "components/layout"
import indexJson from "../../../reduced-index.json"
import * as JsSearch from "js-search"
import Fuse from "fuse.js"
import { apis as API } from "@sog/sdk"
import store from "state/store"
import AppManager from "services/app-manager"
import { Typography } from "components/typography"
import * as Clickable from "components/clickable"
import { Link } from "gatsby"
import { useMediaQuery } from "@mui/material"
import * as styles from "./style.module.scss"
import { DataCollector, DataCollectorAction } from "@sog/sdk"

const Search = (props: any) => {
  const providers = store.getState().main.provider
  const isTablet = !useMediaQuery("(min-width:1024px)")

  const [searchQuery, setSearchQuery] = useState(props.location?.state?.searchQuery || "")
  // const [searchResults, setSearchResults] = useState([])
  // const [search, setSearch] = useState([])
  // const [isLoading, setIsLoading] = useState(true)
  const [paginatedResultsList, setPaginatedResultsList] = useState([])
  const [paginatedCounts, setPaginatedCounts] = useState([])
  const [fuseResults, setFuseResults] = useState([])
  const [synonyms, setSynonyms] = useState(AppManager.getInstance().getProviderSynonyms())

  const [page, setPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(10)

  const fuse = new Fuse(indexJson, {
    keys: [
      // articles
      {
        name: "pageContext.article.title",
        weight: 2,
      },
      {
        name: "pageContext.article.summary",
        weight: 1,
      },

      // cc provider pages
      {
        name: "pageContext.heading",
        weight: 2,
      },
      {
        name: "pageContext.autoDescription",
        weight: 1,
      },

      // cc product pages
      {
        name: "pageContext.product.autoDescription",
        weight: 1,
      },
      {
        name: "pageContext.product.description",
        weight: 1,
      },
      {
        name: "pageContext.product.cardHeading",
        weight: 2,
      },

      // feature and rewards pages
      // {
      // name: "pageContext.heading",
      // weight: 2,
      // },

      // hl provider pages
      {
        name: "pagecContext.heading",
        weight: 2,
      },
      {
        name: "autoDescription",
      },

      // hl product pages
      {
        name: "pageContext.categoryJson.product.nameLong",
        weight: 2,
      },
      { name: "pageContext.categoryJson.products." },

      // other pages
      {
        name: "pageContext.title",
        weight: 2,
      },
      {
        name: "pageContext.description",
        weight: 1,
      },
    ],
    includeScore: true,
  })

  // const rebuildIndex = () => {
  //   if (indexJson) {
  //     const dataToSearch = new JsSearch.Search("id")
  //     dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
  //     dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
  //     dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id")
  //     dataToSearch.addDocuments(indexJson)
  //     // dataToSearch.addDocuments(indexJson.products)
  //     dataToSearch.addIndex("path")
  //     dataToSearch.addIndex("pageContext")
  //     setSearch(dataToSearch)
  //     setIsLoading(false)
  //   }
  // }

  const updateFuseResults = results => {
    const resultsList = []
    const counts = []

    let subResultsList = []
    let count = 0
    for (let i = 0; i < results.length; i++) {
      subResultsList.push(results[i])
      count += 1
      if (count === resultsPerPage) {
        resultsList.push(subResultsList)
        counts.push(count)
        subResultsList = []
        count = 0
      }
    }
    resultsList.push(subResultsList)
    counts.push(count)

    setPaginatedResultsList(resultsList)
    setPaginatedCounts(counts)
    setFuseResults(results)
  }

  const searchData = (inputString: string, pageLoad = false) => {
    if (!pageLoad) {
      DataCollector.getInstance().addAction(DataCollectorAction.SEARCH_PERFORMED, { query: inputString, location: "SEARCH_PAGE" })
    }
    inputString = inputString.toLowerCase()

    // let queryResult = []
    let fuseResult = []

    // if (providers.some(p => p.name.toLowerCase() === inputString)) {
    //   queryResult = search.search(inputString)
    // }
    // else if (inputString in synonyms) {
    //   queryResult = search.search(synonyms[inputString])
    // }

    if (inputString in synonyms) {
      fuseResult = fuse.search(synonyms[inputString])
    } else {
      fuseResult = fuse.search(inputString)
    }

    // setSearchResults(queryResult)
    setPage(0)
    updateFuseResults(fuseResult)
  }

  useEffect(() => {
    // rebuildIndex()
    searchData(searchQuery, true)
  }, [])

  const searchBar = () => {
    if (isTablet) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%", border: "1px solid #E8E8E8" }}>
            <input
              id="search-bar-page"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              name="search"
              autoComplete="off"
              style={{ fontSize: "18px", width: "80%", height: "60px", border: "none" }}
              onChange={e => {
                setSearchQuery(e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  searchData(searchQuery)
                }
              }}
            />
            <div style={{ display: "flex", alignItems: "center", backgroundColor: "#FFFFFF" }}>
              <Clickable.Image
                width="20px"
                height="20px"
                imgUrl={"/images/icon_search_black.png"}
                onClick={() => {
                  searchData(searchQuery)
                }}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px", marginBottom: "20px", border: "1px solid #E8E8E8" }}>
          <input
            id="search-bar-page"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            name="search"
            autoComplete="off"
            style={{ fontSize: "24px", width: "100%", paddingLeft: "40px", paddingRight: "40px", height: "80px", border: "none" }}
            onChange={e => {
              setSearchQuery(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                searchData(searchQuery)
              }
            }}
          />
          {/* <div style={{ display: "flex", alignItems: "center", backgroundColor: "#FFFFFF" }}>
            <Clickable.Image
              width="20px"
              height="20px"
              imgUrl={"/images/icon_search_black.png"}
              onClick={() => {
                searchData(searchQuery)
              }}
            />
          </div> */}
        </div>
      )
    }
  }

  const renderFuseResults = () => {
    if (fuseResults.length > 0) {
      return paginatedResultsList[page].map((r, i) => (
        <div style={{ marginBottom: "30px" }}>
          <Typography.P comp="medium-bold">{r.item.pageContext.article?.title || r.item.pageContext.heading || r.item.pageContext.product?.cardHeading || r.item.pageContext.title}</Typography.P>
          <Typography.P comp="content-p">{r.item.pageContext.article?.summary || r.item.pageContext.autoDescription || r.item.pageContext.product?.autoDescription || r.item.pageContext.description}</Typography.P>
          <Link to={r.item.path} style={{ textDecoration: "none", fontSize: "18px", color: "#4F8CCC", fontFamily: "Arboria-Book, sans-serif" }}>
            www.stayorgo.com.au{r.item.path}
          </Link>
        </div>
      ))
    }
    return <div style={{ marginTop: "30px" }}>No Results</div>
  }

  const pageButtons = [
    { text: "<< First", toPage: 0, disabled: 0 },
    { text: "< Prev", toPage: page - 1, disabled: 0 },
    { text: "Next >", toPage: page + 1, disabled: paginatedResultsList.length - 1 },
    { text: "Last >>", toPage: paginatedResultsList.length - 1, disabled: paginatedResultsList.length - 1 },
  ]

  const currentResultShown = (resultsPerPage * page + 1).toString() + "..." + (resultsPerPage * page + paginatedCounts[page]).toString()

  const updatePage = pb => {
    DataCollector.getInstance().addAction(DataCollectorAction.PAGE_SELECTED, { button: pb.text.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, ""), page: pb.toPage + 1 })
    setPage(pb.toPage)
  }

  const pagination = () => {
    if (fuseResults.length === 0) {
      return null
    }
    return (
      <div style={{ width: isTablet ? "100%" : "80%", margin: "auto", marginTop: "30px", marginBottom: "30px" }}>
        {!isTablet ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {pageButtons.slice(0, 2).map(pb => {
              return (
                <Clickable.Text className={styles.pageButton} secondary noBorder disabledBackgroundColor="#ffffff" disabled={pb.disabled === page} onClick={() => updatePage(pb)}>
                  <p>{pb.text}</p>
                </Clickable.Text>
              )
            })}

            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography.P>{fuseResults.length === 0 ? "No Result" : currentResultShown + " of " + fuseResults.length + " results"}</Typography.P>
              {/* <Typography.P>Page {page + 1} of {paginatedResultsList.length}</Typography.P> */}
            </div>

            {pageButtons.slice(2, 4).map(pb => {
              return (
                <Clickable.Text className={styles.pageButton} secondary noBorder disabledBackgroundColor="#ffffff" disabled={pb.disabled === page} onClick={() => updatePage(pb)}>
                  <p>{pb.text}</p>
                </Clickable.Text>
              )
            })}
          </div>
        ) : (
          <div>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <Typography.P>{"Results " + currentResultShown + " of " + fuseResults.length}</Typography.P>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {pageButtons.map(pb => {
                return (
                  <Clickable.Text className={styles.pageButton} secondary noBorder disabledBackgroundColor="#ffffff" disabled={pb.disabled === page} onClick={() => updatePage(pb)}>
                    <p>{pb.text}</p>
                  </Clickable.Text>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <Layout>
      <div className="container-center">
        <div className="home-container">
          {searchBar()}
          {pagination()}
          {renderFuseResults()}
          {pagination()}
        </div>
      </div>
    </Layout>
  )
}

export default Search
